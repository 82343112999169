/*
Cheat Sheet for Updating Objects and Arrays in React State
https://dev.to/andyrewlee/cheat-sheet-for-updating-objects-and-arrays-in-react-state-48np 


How to Build Dynamic Forms in React
https://www.freecodecamp.org/news/build-dynamic-forms-in-react/
*/
import React, {useEffect, useState} from "react";
import {post, popup, alert} from "../util/util.js"
import {Form, Row, Col, Card, Button, Badge, InputGroup} from "react-bootstrap"
import moment from "moment"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFaucetDrip, faBolt, faPen, faHouse} from '@fortawesome/free-solid-svg-icons'
import { lazy } from 'react'


export default function House(props){
  const [house, setHouse] = useState()
  const [houseList, setHouseList] = useState([])
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'))
  const [weList, setWeList] = useState([])
  const [userType, setUserType] = useState('')
  const [houseObj, setHouseObj]=useState({})
  
  useEffect(()=>{
    post('/house/getHouseList',{},d=>{
      setHouseList(d.data)
    })
    if(!window.localStorage.getItem('firstUse')){
      alert(<div>欢迎使用1.5X租务管理系统。<br/>如果没有可以选择的房屋，<br/>请前往<br/>“个人信息及房屋管理”添加新房屋</div>,"",()=>{
        window.localStorage.setItem('firstUse', true)
      })
    }
  },[])

  const changeHouse = (e)=>{
    setHouse(e.target.value)
    let obj = houseList.find(t=>t.value==e.target.value)
      if(obj){
        setHouseObj(obj)
        setUserType(obj.Type)
      }else {
        setUserType('')
      }
      
    post('/house/getWE',{house:e.target.value}, d=>{
      setWeList(d.data)
    });
  }

  const showDetails = (data, idx) =>{
    const Room = lazy(() => import('./room'))
    popup(<Room data={data} userType={userType} cb={(d)=>{
      const newWeList = [...weList]
      newWeList[idx] = d
      setWeList(newWeList)
    }}/>, `編輯 ${data.no} 單位資料`)
  }

  const changeText = (idx, tp, e)=>{
    const newWEList = [...weList]
    newWEList[idx][tp] = e.target.value
    setWeList(newWEList)
  }

  const save = (idx) =>{
    post('house/save',{...weList[idx], currentDate, house}, d=>{
      
      if(d.error){
        alert(d.error)
      } else{
        alert(`${weList[idx].no} 保存成功`)
        const newWEList = [...weList]
        newWEList[idx]=d.data
        setWeList(newWEList)
      }
    })
  }

  const showHourse = (data) =>{
    const HouseDetail = lazy(() => import('./houseDetail'))
    popup(<HouseDetail data={data} userType={userType} cb={(d)=>{
    }}/>, `编辑 ${data.label} 资料`)
  }

  const addRoom = (d)=>{
    const newWeList = [...weList]
      newWeList.push(d)
      setWeList(newWeList)
  }

  const newRoom = (data) =>{
    const AddRoom = lazy(() => import('./addRoom'))
    popup(<AddRoom data={data} cb={addRoom}/>, `新加单位资料`)
  }

  const bill = (data) =>{
    const CheckWE = lazy(() => import('./checkWE'))
    popup(<CheckWE data={data, weList}/>, `檢查水電抄表`)
  }

  const orderRoom = (data) =>{
    const OrderRoom = lazy(() => import('./orderRoom'))
    popup(<OrderRoom data={data} />, `單位排序`)
  }

  return (
    <Form>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>选择房屋</Form.Label>
                  <Form.Select aria-label="Default select example" onChange={(e)=>changeHouse(e)} value={house}>
                  {houseList.map((t, i)=>{
                    return <option value={t.value} key={i}>{t.label}</option>
                  })}
                </Form.Select>
                {userType=='OWNER'&&<Button variant="primary" onClick={(e)=>showHourse(houseObj)}>编辑房屋资料</Button>}
                {userType=='OWNER'&&<Button variant="primary" onClick={(e)=>newRoom(houseObj)}>新加单位</Button>}
                <Button variant="primary" onClick={(e)=>bill(houseObj)}>檢查水電抄表</Button>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group >
                  <Form.Label>抄表日期</Form.Label>
                  <Form.Control type="date" name="dob" placeholder="抄表日期" onChange={(e)=>setCurrentDate(e.target.value)} value = {currentDate}/>
                </Form.Group>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={12}>
                {weList.map((t, i)=>{
                  let sameW = moment(t.lastWaterDt).isSame(moment(currentDate))
                  let sameE = moment(t.lastEleDt).isSame(moment(currentDate))

                  return (
                    <Card key={i} style={{marginBottom:'15px'}}>
                      <Card.Header>{t.no}<FontAwesomeIcon icon={faPen} onClick={()=>showDetails(t, i)}/></Card.Header>
                      <Card.Body>
                        <Row>
                          <Col md={6} sm={6}>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faFaucetDrip} />
                              </InputGroup.Text>
                              <Form.Control type="number" placeholder="本次用水量" onChange={(e)=>changeText(i, 'newWater', e)} value={t.newWater||''}/>  
                            </InputGroup>
                            <Form.Text><Badge bg={sameW?"success":"light"} text={sameW?"light":"dark"}>最后抄表水量及日期:{t.lastWater}({t.lastWaterDt})</Badge></Form.Text>
                            
                          </Col>
                          <Col md={6} sm={6}>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faBolt} />
                              </InputGroup.Text>
                              <Form.Control type="number"placeholder="本次用電量" onChange={(e)=>changeText(i, 'newEle', e)} value={t.newEle||''}/> 
                            </InputGroup> 
                            <Form.Text><Badge bg={sameE?"success":"light"} text={sameE?"light":"dark"}>最后抄表电量及日期:{t.lastEle}({t.lastEleDt})</Badge></Form.Text>
                            
                          </Col>
                        </Row>
                      </Card.Body>
                      <Button variant="primary" onClick={(e)=>save(i)}>保存 {t.no}抄表记录</Button>
                    </Card>
                  )
                })}
              </Col>
            </Row>
          </Form>
  )
}
