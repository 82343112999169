import React, {useState} from "react";
import { Button,  Modal} from "react-bootstrap"


export function Popup(props){
    const [show, setShow] = useState(true)
    const [buttons] = useState(props.buttons||[])

    const close = () =>setShow(false)
    

    return (
        <Modal show={show} backdrop = {true} size="lg" centered >
          <Modal.Header closeButton onHide={()=>{close();props.closePopup(); props.closeCB && props.closeCB()}}>
              <Modal.Title>{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              {props.elem}
          </Modal.Body>
          <Modal.Footer>
              {buttons.map(t=>{
                  return (
                      <Button variant={t.style}>{t.name}</Button>
                  )
              })}
          </Modal.Footer>
      </Modal>
    )
}

export function Alert(props){
    const [show] = useState(true)
    

    return (
        <Modal show={show} backdrop = {true} size="sm" centered>
          <Modal.Header >
              <Modal.Title>{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p>{props.msg}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={()=>{
                props.closePopup()
                if(props.cb){
                    props.cb()
                }
            }}>確定</Button>
          </Modal.Footer>
      </Modal>
    )
}

export function Prompt(props){
    const [show] = useState(true)

    return (
        <Modal show={show} backdrop = {true} size="sm" centered>
          <Modal.Header >
              <Modal.Title>{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p>{props.msg}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={()=>{
                props.closePopup()
                if(props.cb){
                    props.cb(true)
                }
            }}>是</Button>
            <Button variant="primary" onClick={()=>{
                props.closePopup()
                if(props.cb){
                    props.cb(false)
                }
            }}>不是</Button>
          </Modal.Footer>
      </Modal>
    )
}
