import React, { useState } from "react";
import {post, alert} from "../util/util.js"
import {Form, Button} from "react-bootstrap"

export default function Registry(props){
    const [info,setInfo] = useState({})

    const handleSubmit = (event)=>{
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
       
        if (form.checkValidity() === true) {

            if(info.pwd!=info.repwd){
                alert('两次密码不相同')
            }else 
            post('/registry/save',{info}, (d)=>{
                if(d.ok) {
                    alert('注册成功','',()=>{
                        window.localStorage.clear()
                        props.cb(d.data)
                        props.closePopup()
                    })
                }
                else {
                    alert(`${d.msg||'保存失败，请再尝试'}`)
                }
            })
        }
    }

    const changeText = (e)=>{        
        if(e.target.id){
            const newInfo = {...info}
            newInfo[e.target.id]=e.target.value
           setInfo(newInfo)
        }
    }

    return (
        <Form onSubmit={(e)=>handleSubmit(e)}>
            <Form.Group>
                <Form.Label > <span className="glyphicon glyphicon-user" ></span> 电话号码:</Form.Label>
                <Form.Control minLength={8} type="number" defaultValue={info.phone} id="phone" placeholder="电话号码" name="phone" required onChange={(e)=>changeText(e)} ></Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label > <span className="glyphicon glyphicon-user" ></span> 用户名:</Form.Label>
                <Form.Control minLength={2} type="text" defaultValue={info.name} id="name" placeholder="用户名" name="name" required onChange={(e)=>changeText(e)} ></Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label > <span className="glyphicon glyphicon-user" ></span> 密码:</Form.Label>
                <Form.Control minLength={4} type="password" id="pwd" placeholder="密码(最少四位)" name="pwd" required onChange={(e)=>changeText(e)}></Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label > <span className="glyphicon glyphicon-lock"></span> 再次输入密码:</Form.Label>
                <Form.Control  minLength={4} type="password"  id="repwd" placeholder="再次输入密码(最少四位)" name="repwd" required  onChange={(e)=>changeText(e)}></Form.Control>
            </Form.Group>
            <br/>
            <div className="d-grid gap-2">
                <Button variant="primary" type='submit'>保存</Button>
            </div>
        </Form>
    )
}