import React, { useState, useEffect } from "react";
import {post, alert, prompt} from "../util/util.js"
import {Form, Button,Accordion, ListGroup, Row, Col} from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus} from '@fortawesome/free-solid-svg-icons'

export default function UserInfo(props){
    const [info,setInfo] = useState({})
    const [houseList,setHouseList] = useState([])

    useEffect(()=>{
        post('/userInfo/getDetails',{},d=>{
            setInfo(d.userInfo)
            setHouseList(d.houseList)
          })
      },[])

    const handleSubmit = (event)=>{
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
       
        if (form.checkValidity() === true) {

            if(info.pwd!='' && info.pwd!=info.repwd){
                alert('两次密码不相同')
            }else 
            post('/userInfo/save',{info}, (d)=>{
                console.log(d)
                if(d.ok) {
                    alert('保存成功')
                    const newList = houseList.filter(t=>t.id!==info.id)
                    setHouseList(newList)
                }
                else {
                    alert(`${d.msg||'保存失败，请再尝试'}`)
                }
            })

        }
    }

    const changeText = (e)=>{        
        if(e.target.id){
            const newInfo = {...info}
            newInfo[e.target.id]=e.target.value
           setInfo(newInfo)
        }
    }

    const changeHouseField = (e, idx)=>{
        if(e.target.id){
            const newList = [...houseList]
            newList[idx][e.target.id]=e.target.value
            setHouseList(newList)
        }
    }

    const addHouse = () =>{
        const newList = [...houseList]
        newList.push({id:new Date().getTime(), no:'', isNew:true})
        setHouseList(newList)
    }

    const removeHouse = (r)=>{
        prompt((<>是否需要刪除此記錄？</>),'',(ok)=>{
            if(ok) {
                if(r.isNew){
                    const newList = houseList.filter(t=>t.id!==r.id)
                    setHouseList(newList)
                } else {
                    post('/userInfo/removeHouse',{id:r.id},(d)=>{
                        if(d.ok===true){
                            const newList = houseList.filter(t=>t.id!==r.id)
                            setHouseList(newList)
                            alert('刪除成功')
                        }
                        else
                            alert('未能刪除，请稍后尝试') 
                    })
                }
            }
        })
    }

    const saveHouse = (r)=>{
        post('/userInfo/saveHouse',{data:r},(d)=>{
            if(d.ok===true){
                const newList = [...houseList]
                const idx = newList.findIndex(t=>t.id===r.id)
                newList[idx]=d.data
                setHouseList(newList)
                alert('保存成功')
            }
            else
                alert('未能保存，请稍后尝试') 
        })
    }

    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>个人信息</Accordion.Header>
                <Accordion.Body>
                <Form  onSubmit={(e)=>handleSubmit(e)}>
                        <Form.Group>
                            <Form.Label > <span className="glyphicon glyphicon-user" ></span> 电话号码:</Form.Label>
                            <Form.Control minLength={8} type="number" defaultValue={info.phone} id="phone" placeholder="电话号码" name="phone" required onChange={(e)=>changeText(e)} required></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label > <span className="glyphicon glyphicon-user" ></span> 用户名:</Form.Label>
                            <Form.Control minLength={2} type="text" defaultValue={info.name} id="name" placeholder="用户名" name="name" required onChange={(e)=>changeText(e)} required></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label > <span className="glyphicon glyphicon-user" ></span> 新密码:</Form.Label>
                            <Form.Control minLength={4} type="password" id="pwd" placeholder="新密码" name="pwd" onChange={(e)=>changeText(e)}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label > <span className="glyphicon glyphicon-lock"></span> 再次输入新密码:</Form.Label>
                            <Form.Control  minLength={4} type="password"  id="repwd" placeholder="再次输入新密码" name="repwd"   onChange={(e)=>changeText(e)}></Form.Control>
                        </Form.Group>
                        <br/>
                        <div className="d-grid gap-2">
                            <Button type="submit" variant="primary" >保存</Button>
                        </div>
                </Form>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>房屋管理</Accordion.Header>
                <Accordion.Body>
                    <FontAwesomeIcon icon={faPlus} onClick={()=>addHouse()}/>
                    <ListGroup>
                        {houseList.map((t,i)=><ListGroup.Item key={i}>
                            <Row className={t.isNew?"bg-secondary":""}>
                                <Form>
                                <Col md={4}>
                                    <Form.Control type="text" required placeholder="房屋名称" id="name" value={t.name||''} onChange={(e)=>changeHouseField(e, i)}/>
                                    <Form.Text>(例如 福田祠堂村999号)</Form.Text>
                                </Col>
                                <Col md={4}>
                                    <Button style={{float: 'right'}} variant="danger" onClick={(e)=>removeHouse(t)}>刪除</Button>
                                    <Button style={{float: 'right'}} variant="primary" onClick={(e)=>saveHouse(t)}>保存</Button>
                                </Col>
                                </Form>
                            </Row>
                        </ListGroup.Item>)}
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>


        
    )
}