import React, { useState, useEffect } from "react";
import {post, alert} from "../util/util.js"
import DataGrid from 'react-data-grid';
import moment from "moment"
import { ButtonGroup, Button } from "react-bootstrap";

export default function SystemLog(props){
    const [logs,setLogs] = useState({})
    const [users,setUsers] = useState([])

    const [currentDate, setCurrentDate]=useState(moment().format('YYYY-MM-DD-HH'))
    const cols = [
        { key: 'action', name: 'Action', resizable: true},
        { key: 'user', name: 'User' ,resizable: true},
        { key: 'ip', name: 'IP' ,  resizable: true},
        { key: 'time', name: 'Time' , resizable: true}
      ];

    const colsUser = [
        { key: 'name', name: 'Name', resizable: true},
        { key: 'phone', name: 'Phone' ,resizable: true},
        { key: 'last_login_dt', name: 'Time' , resizable: true}
    ]; 
      
    useEffect(()=>{
        post('/systemLog/getData',{currentDate},d=>{
            setLogs(d.logs)
            setUsers(d.users)
        })
    },[currentDate])

    const set =(tp)=>{
       
        if (tp=='n' && moment(currentDate,'YYYY-MM-DD-HH').add(1,'hour').isAfter(moment()))
            alert('已经最新')
        else {
            setLogs([])
            setCurrentDate(tp=='p'?moment(currentDate,'YYYY-MM-DD-HH').add(-1,'hour').format('YYYY-MM-DD-HH'):moment(currentDate,'YYYY-MM-DD-HH').add(1,'hour').format('YYYY-MM-DD-HH'))
        }
    }

    return (
        <>
            <ButtonGroup>
                <Button onClick={()=>set('p')}>上一个</Button>
                <Button onClick={()=>set('n')}>下一个</Button>
            </ButtonGroup>
            {currentDate}
            <br/>
            <DataGrid columns={cols} rows={logs} />
            <br/>
            <DataGrid columns={colsUser} rows={users} />
        </>
    );
}