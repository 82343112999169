import { Outlet } from "react-router-dom";
import React from "react";
import {Navbar, Nav, Container,NavDropdown} from 'react-bootstrap';
import './App.css';
import { useAuth } from "./components/useAuth.js";
import { post, prompt, popup, alert} from "./util/util";
import { lazy } from 'react'

export default function App(props){
  const {logout, user} = useAuth()

  const appLogout = ()=>{
    prompt('是否登出系统?','', (r)=>{
      if(r===true)
        post("/login/logout", {},(d)=>{
          logout()
        })
    })
  }

  const help = ()=>{
    const Help = lazy(() => import('./screens/help'))
    popup(<Help/>, `幫助提示`)
  }

  const userDetail = ()=>{
    const UserInfo = lazy(() => import('./screens/userInfo'))
    popup(<UserInfo/>, `修改密码`)
  }

  const about = ()=>{
    const About = lazy(() => import('./screens/about'))
    popup(<About/>,'关于')
  }

  return (
    <div className="App">
         <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand onClick={about}><img src="../images/favicon-circle.png" alt="logo" height="30px"/>1.5X 租务管理系统</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="house">租务管理</Nav.Link>
                <Nav.Link href="bill">打单</Nav.Link>
                <Nav.Link href="userInfo">个人信息及房屋管理</Nav.Link>
                <Nav.Link href="#" onClick={help}>帮助</Nav.Link>
                <Nav.Link href="#" onClick={()=>appLogout()}>登出</Nav.Link>
                {user.isAdm&&<NavDropdown title="系統設置" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/systemLog">系统日志</NavDropdown.Item>
                  </NavDropdown>}
              </Nav>
              <Navbar.Text>
                用戶:{user.phone}
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container fluid>
          <Outlet />
        </Container>
    </div>
  )
}