import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./useAuth.js";

export const ProtectedLayout = () => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/login" state={{msg:'請登入系統'}}/>;
  }
  
  return (
    <div>
      <Outlet />
    </div>
  )
};