
import {Popup, Alert, Prompt} from "./popup.js"
import React, {Suspense} from "react";
import { createRoot } from "react-dom/client";
import {useAuth} from "../components/useAuth"
import {Spinner} from "react-bootstrap"

async function postData(url = '', data = {}, spin, spintime) {
    // Default options are marked with *
    let r = spin ? spinner():undefined;

    const response = await fetch('/api/'+url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });

    if(r && r.r && r.node) {
      setTimeout(()=>{
        r.r.unmount();
        r.node.remove();
      },spintime)
      
    }

    if(response.status==404){
      window.localStorage.removeItem('user');
      window.location.href='/login'
      //return response.json()
    }
    else if(response.headers && response.headers.get('Content-Type').includes('pdf'))
      return response.blob(); 
    else if(response.headers && response.headers.get('Content-Type').includes('json')) {
      return response.json();
      /*let json = await response.json()
      if(json.AUTH && json.AUTH=='NO')
        window.location.href='/login'
      else 
        return json; // parses JSON response into native JavaScript objects
        */
    }
  }
  
export const post = (api, data, cb, spinner=false, spintime=0)=>{
    postData(api, data, spinner, spintime)
    .then(data => {
     cb(data) 
    });
}

export const popup = (elem, title) =>{

  const node = document.createElement("div");
  document.body.appendChild(node);
  
  let r = createRoot(node)
  const clear = () => {
    r.unmount();
    node.remove();
  }

  elem = React.cloneElement(elem, {closePopup:clear})

  const el = <Suspense>{elem}</Suspense>

  r.render(<Popup title={title} elem={el} closePopup={()=>clear()}/>);
  return 
}

export const alert= (msg, title, cb) =>{
  const node = document.createElement("div");
  document.body.appendChild(node);
  
  let r = createRoot(node)
  const clear = () => {
    r.unmount();
    node.remove();
  }

  r.render(<Alert title={title||'提示'} msg={msg} closePopup={()=>clear()} cb={cb}/>);
  return 
}

export const prompt= (msg, title, cb) =>{
  const node = document.createElement("div");
  document.body.appendChild(node);
  
  let r = createRoot(node)
  const clear = () => {
    r.unmount();
    node.remove();
  }

  
  r.render(<Prompt title={title||'提示'} msg={msg} closePopup={()=>clear()} cb={cb}/>);
  return 
}


export const allowRent =(tp)=>{
  return ['OWNER','ADMIN'].indexOf(tp)>=0
}

const spinner = ()=>{
  const node = document.createElement("div");
  document.body.appendChild(node);
  
  let r = createRoot(node)
  const clear = () => {
    
  }
 
  r.render(
    <Spinner animation="border" variant="primary" role="status" size="lg" className="fetch-spinner">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  )
    return {r,node}
}