/*
Cheat Sheet for Updating Objects and Arrays in React State
https://dev.to/andyrewlee/cheat-sheet-for-updating-objects-and-arrays-in-react-state-48np 


How to Build Dynamic Forms in React
https://www.freecodecamp.org/news/build-dynamic-forms-in-react/
*/
import React, { useState, useEffect } from "react";
import {post, alert} from "../util/util.js"
import {Form, Row, Col, Button, InputGroup, Carousel, ButtonGroup} from "react-bootstrap"
import moment from "moment"


export default function Bill(props){
  const years = [2022,2023,2024,2025,2026,2027,2028,2029,2030,2031,2032]
  const month = [1,2,3,4,5,6,7,8,9,10,11,12]
  
  const [report, setReport] =useState('')
  const [houseList, setHouseList] = useState([])
  const [currentMonth, setCurrentMonth] = useState(moment().month()+1)
  const [currentYear, setCurrentYear] = useState(moment().year())
  const [house, setHouse] = useState()
  const [rptImages, setRptImages] = useState([])
  const [reportList, setReportList] = useState([])
  const [selectRptList, setSelectRptList] = useState([])
  const [startPreview, setStartPreview] = useState(false)

  useEffect(()=>{
    post('/bill/getHouseList',{},d=>{
      setHouseList(d.data)
      setReportList(d.reportList)
    })
  },[])

  const changeHouse = (e, v)=>{
    setHouse(e.target.value)
    let obj = houseList.find(t=>t.value==e.target.value)
      if(obj){
        const rptl = reportList.filter(t=>t.acl.indexOf(obj.Type)>=0)
        setSelectRptList(rptl||[])
        setReport(rptl[0]?rptl[0].value:'')
      }else {
        setSelectRptList([])
        setReport('')
      }
  }

  const changeReport = (e, v)=>{
    setReport(e.target.value)
  }

  const changeDate = (tp, e)=>{
      if(tp==='years')
        setCurrentYear(e.target.value)
      else 
        setCurrentMonth(e.target.value)
  }

  const print = (tp)=>{

      if(moment().set('year',currentYear).set('month', currentMonth-1).set('date',1).isBefore(moment().set('date',1), 'month')){
        alert('不允许打印旧单，请选择未来的日期')
        return false
      }

      if(tp=='p') {
        setRptImages([])
        setStartPreview(false)    
      } 

      post('/bill/print',{house, currentYear, currentMonth, report, tp}, d=>{
        if(d.error)
          alert(d.error)
        else {
          if(tp=='d')
            window.open(window.URL.createObjectURL(d));
          else {
            if(!d || !d.images || d.images.length==0 )
              alert('系统繁忙，请再尝试')
            setRptImages(d.images)
            setStartPreview(true)    
          }
        }
      }, true, 2000);
  }

  return (
    <Form>
      <Row>
        <Col md={4}>
          <Form.Group>
            <Form.Label>选择房屋</Form.Label>
            <Form.Select aria-label="Default select example" onChange={changeHouse} value={house}>
              {houseList.map((t, i)=>{
                return <option value={t.value} key={i}>{t.label}</option>
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group>
            <Form.Label>打单类型</Form.Label>
            <Form.Select onChange={changeReport} value={report}>
            {selectRptList.map((t, i)=>{
              return <option value={t.value} key={i}>{t.label}</option>
            })}
          </Form.Select>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
          
            <Form.Label>打单月份</Form.Label>
            <InputGroup>
            <Form.Select onChange={(e)=>changeDate('years',e)} value = {currentYear}>
              {years.map((t, i)=>{
                  return <option value={t} key={i}>{t}</option>
              })}
            </Form.Select>
            <Form.Select onChange={(e)=>changeDate('month',e)} value = {currentMonth}>
              {month.map((t, i)=>{
                  return <option value={t} key={i}>{t}</option>
              })}
            </Form.Select>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <br/>
      <ButtonGroup>
        <Button variant="primary" onClick={(e)=>print('p')}>预览</Button>
        <Button variant="primary" onClick={(e)=>print('d')}>下载</Button>
      </ButtonGroup>
      <br/>
      {startPreview && rptImages.length==0 &&<Form.Text>*系统繁忙，请再尝试‘预览’</Form.Text>}
      <br/><br/>
      <Row>
        <div style = {{position: 'relative', maxWidth:590, margin:"auto",  "maxHeight":350}}>
          {rptImages.length>0 && <Carousel variant="dark" interval={null}>
              {rptImages.map((t,i)=>{
                return (
                  <Carousel.Item key={i}>
                    <img className="d-block w-100" src={"data:image/png;base64,"+t.base64} alt={t.page}></img>
                  </Carousel.Item>
                )
              })}
          </Carousel>}
        </div>
      </Row>
    </Form>
)
}
